import React, { useEffect, useRef } from 'react';
import { useLoadScript } from '@react-google-maps/api';

const libraries = ['places'];

const GoogleAddressAutocomplete = ({ register, setValue, error, formId }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (isLoaded && !loadError) {
      initAutocomplete();
    }
  }, [isLoaded, loadError]);

  const initAutocomplete = () => {
    const input = document.getElementById(`addressLine1_${formId}`);
    const autocompleteOptions = {
      fields: ['address_components', 'formatted_address', 'geometry'],
      types: ['address'],
      componentRestrictions: { country: 'uk' },
    };
  
    autocompleteRef.current = new window.google.maps.places.Autocomplete(input, autocompleteOptions);
    autocompleteRef.current.addListener('place_changed', handlePlaceSelect);
  
    const applyStyles = () => {
      const pacContainer = document.querySelector('.pac-container');
      if (pacContainer) {
        const isDarkMode = document.documentElement.getAttribute('data-theme') === 'apersudark';
        pacContainer.style.backgroundColor = isDarkMode ? '#1d232a' : '#ededed';
        pacContainer.style.border = '2px solid #881337';
        pacContainer.style.boxShadow = `0 2px 6px rgba(0, 0, 0, ${isDarkMode ? '0.5' : '0.3'})`;
        pacContainer.style.zIndex = '1000';
  
        const pacItems = pacContainer.querySelectorAll('.pac-item, .pac-item span');
        pacItems.forEach(item => {
          item.style.color = isDarkMode ? '#f3f4f6' : '#111827';
        });
  
        const pacItemQueries = pacContainer.querySelectorAll('.pac-item-query');
        pacItemQueries.forEach(query => {
          query.style.color = isDarkMode ? '#f97316' : '#881337';
        });
      }
    };
  
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          applyStyles();
        }
      });
    });
  
    observer.observe(document.body, { childList: true, subtree: true });
  
    const themeObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-theme') {
          applyStyles();
        }
      });
    });
  
    themeObserver.observe(document.documentElement, { attributes: true });
  };

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    if (!place.geometry) {
      console.log("No details available for input: '" + place.name + "'");
      return;
    }
  
    let address1 = '';
    let address2 = '';
    let city = '';
    let county = '';
    let postcode = '';
  
    for (const component of place.address_components) {
      const componentType = component.types[0];
  
      switch (componentType) {
        case 'street_number':
          address1 = `${component.long_name} ${address1}`;
          break;
        case 'route':
          address1 += component.long_name;
          break;
        case 'postal_town':
          city = component.long_name;
          break;
        case 'administrative_area_level_2':
          county = component.long_name;
          break;
        case 'postal_code':
          postcode = component.long_name;
          break;
      }
    };
  
    if (!city && place.formatted_address) {
      const parts = place.formatted_address.split(',');
      city = parts[parts.length - 3].trim();
    }
  
    setValue('addressLine1', address1.trim(), { shouldValidate: true });
    setValue('addressLine2', city, { shouldValidate: true });
    setValue('addressLine3', county, { shouldValidate: true });
    setValue('projectAddressPostcode', postcode, { shouldValidate: true });

    setTimeout(() => {
      const pacContainers = document.getElementsByClassName('pac-container');
      for (let container of pacContainers) {
        container.style.display = 'none';
      }
    }, 100);
  };

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  return (
    <div>
      <label className="input input-bordered flex items-center gap-2 mx-auto">
        <input
          {...register("addressLine1", {
            required: "Address line 1 is required",
          })}
          id={`addressLine1_${formId}`}
          type="text"
          className="grow"
          placeholder="Address Line 1"
        />
  <span className="badge badge-accent badge-xs"></span>
      </label>
      {error && <span className="text-error">{error}</span>}
    </div>
  );
};

export default GoogleAddressAutocomplete;