import { Link } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import LoadingBar from "../../components/LoadingBar";
import { Files, MagnifyingGlass, WarningCircle } from "@phosphor-icons/react";
import ValidateEmail from "../../components/ProfileInfo/ValidateEmail";
import {
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
} from "firebase/firestore";
import { apersuDatabase } from "../../firebase/config";

export default function MyEstimates({ user, uid }) {
  const ESTIMATES_PER_PAGE = 20;
  const [estimateData, setEstimateData] = useState(null);
  const [search, setSearch] = useState("");
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  const fetchEstimates = useCallback(() => {
    const ref = collection(apersuDatabase, `/data/profiles/customers/${uid}/estimates`);
    const q = query(
      ref,
      orderBy("_dateSubmitted", "desc"),
      limit(ESTIMATES_PER_PAGE)
    );

    console.log("MyEstimates useEffect running");

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const results = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEstimateData(results);
        setIsPending(false);
      },
      (error) => {
        console.error("Error fetching estimates:", error);
        setError("Failed to load estimates. Please try again.");
        setIsPending(false);
      }
    );

    return unsubscribe;
  }, [uid]);

  useEffect(() => {
    const unsubscribe = fetchEstimates();
    return () => unsubscribe();
  }, [fetchEstimates]);

  return (
    <div className="md:px-10 px-3">
      <div className="divider divider-primary md:px-5 max-sm:px-3 xl:px-20">
        <p className="pageTitle">My Estimates</p>
      </div>
      <div className="breadcrumbs text-md sticky top-28 sm:top-28 md:top-32 lg:top-36 bg-base-100 z-40 pb-5">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>My Estimates</li>
        </ul>
      </div>
      <div className="sm:hidden text-left pageSubheadingSm">New Estimates</div>
      <div className="max-sm:hidden text-left pageSubheading">
        New Estimates
      </div>

      {!user.emailVerified && (
        <>
          <div className="pageSubtitle text-left max-sm:ps-6 pb-5 ps-20">
            Please verify your email address to submit new projects.<br/>
            If you haven't received the verification email, you can resend it using the button below.
            <br />
          </div>
          <ValidateEmail />
        </>
      )}
      {user.emailVerified && (
        <>
          <div className="sm:hidden">
            <br />
          </div>
          <Link to="/MyEstimates/NewEstimate">
            <button className="btn max-sm:btn-sm btn-secondary">
              {" "}
              <Files size={25} />
              Submit Information for a New Estimate Here.
            </button>
          </Link>
          <br />
          <br />

          <div className="sm:hidden text-left pageSubheadingSm">
            Your Previous Estimates
          </div>
          <div className="max-sm:hidden text-left pageSubheading">
            Your Previous Estimates
          </div>
          <div className="md:hidden text-left">
            Tap the project number to view the estimate details
            <br />
          </div>

          {error && <div className="noEstimatesErrorText">{error}</div>}
          {isPending && <LoadingBar />}
          {!isPending && !error && (
            <>
              <label className="max-md:w-full w-1/2 my-3 input input-secondary input-bordered flex items-center gap-2">
                <MagnifyingGlass />
                <input
                  placeholder="Address, project proposal or project reference..."
                  type="search"
                  className="grow w-10"
                  onChange={(e) => setSearch(e.target.value.toLowerCase())}
                />
              </label>
              <div className="">
                <div className="md:hidden text-center">
                  Status Key:
                  <br />
                  <div className="badge badge-outline px-1 mx-1">Recieved</div>
                  <div className="badge badge-info px-1">Info Required</div>
                  <div className="badge badge-warning px-1 mx-1">Pricing</div>
                  <div className="badge badge-success px-1">Complete</div>
                  <div className="badge badge-error px-1 mx-1">Cancelled</div>
                </div>
                <table
                  className="table card shadow-xl mx-auto lg:w-11/12 md:w-full"
                  style={{ tableLayout: "fixed" }}>
                  {/* head */}
                  <thead>
                    <tr>
                      <th className="max-md:hidden" style={{ width: "10%" }}>Project Reference</th>
                      <th className="md:hidden" style={{ width: "10%" }}>Ref</th>
                      <th className="max-md:hidden" style={{ width: "20%" }}>Project Proposal</th>
                      <th className="md:hidden" style={{ width: "20%" }}>Project</th>
                      <th className="max-md:hidden" style={{ width: "30%" }}>Project Address</th>
                      <th className="md:hidden" style={{ width: "40%" }}>Address</th>
                      <th className="max-md:hidden text-center" style={{ width: "15%" }}>Project Status</th>
                      <th className="md:hidden text-center" style={{ width: "10%" }}>Status</th>
                      <th className="max-md:hidden" style={{ width: "12%" }}>{" "}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* row 1 */}
                    {estimateData && (
                      <>
                        {(() => {
                          const filteredEstimates = estimateData.filter(
                            (estimate) => {
                              const searchTerm = search.toLowerCase();
                              return (
                                searchTerm === "" ||
                                (estimate.addressLine1 &&
                                  estimate.addressLine1
                                    .toLowerCase()
                                    .includes(searchTerm)) ||
                                (estimate.addressLine2 &&
                                  estimate.addressLine2
                                    .toLowerCase()
                                    .includes(searchTerm)) ||
                                (estimate.addressLine3 &&
                                  estimate.addressLine3
                                    .toLowerCase()
                                    .includes(searchTerm)) ||
                                (estimate.projectAddressPostcode &&
                                  estimate.projectAddressPostcode
                                    .toLowerCase()
                                    .includes(searchTerm)) ||
                                (estimate.projectSplitProposal &&
                                  estimate.projectSplitProposal
                                    .toLowerCase()
                                    .includes(searchTerm)) ||
                                (estimate.projectNumber &&
                                  estimate.projectNumber
                                    .toLowerCase()
                                    .includes(searchTerm))
                              );
                            }
                          );

                          if (filteredEstimates.length === 0) {
                            return (
                              <tr>
                                <td
                                  colSpan="8"
                                  className="text-center text-red-500">
                                  <div className="flex items-center justify-center space-x-2">
                                    <WarningCircle size={25} />
                                    <span>
                                      No estimates match your search. Please
                                      check and try again.
                                    </span>
                                    <WarningCircle size={25} />
                                  </div>
                                </td>
                              </tr>
                            );
                          } else {
                            return filteredEstimates.map((estimate) => (
                              <tr key={estimate.id} className="hover">
                                <th className="max-md:hidden">
                                  {estimate.projectNumber}
                                </th>
                                <th className="md:hidden text-info sm:max-w-5">
                                  <Link
                                    to={`/MyEstimates/Estimate/${estimate.id}`}>
                                    {estimate.projectNumber}
                                  </Link>
                                </th>
                                <td className="max-md:hidden">
                                  {estimate.projectSplitProposal}
                                </td>
                                <td className="md:hidden">
                                  <div
                                    className="tooltip tooltip-top"
                                    data-tip={estimate.projectSplitProposal}>
                                    {estimate.projectSplitProposal?.substring(
                                      0,
                                      20
                                    )}
                                    ...
                                  </div>
                                </td>
                                <td className="max-sm:hidden">
                                  {estimate.addressLine1},
                                  {estimate.addressLine2 && (
                                    <>&nbsp;{estimate.addressLine2},</>
                                  )}
                                  {estimate.addressLine3 && (
                                    <>&nbsp;{estimate.addressLine3},</>
                                  )}
                                  &nbsp;
                                  {estimate.projectAddressPostcode}.
                                </td>
                                <td className="sm:hidden">
                                  {estimate.addressLine1},{" "}
                                  {estimate.projectAddressPostcode}
                                </td>
                                <td className="place-content-center text-center ">
                                  <div
                                    className="tooltip max-md:tooltip-left"
                                    data-tip={estimate.projectStatusTooltip}>
                                    <div
                                      className={`badge md:badge-md xl:badge-lg xl:w-32 md:w-20 badge-${estimate.projectStatusBadge}`}>
                                      <div className="max-md:hidden">
                                        {estimate.projectStatus}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="max-md:hidden">
                                  <Link
                                    to={`/MyEstimates/Estimate/${estimate.id}`}>
                                    <button className="btn btn-sm btn-primary">
                                      Details
                                    </button>
                                  </Link>
                                </td>
                              </tr>
                            ));
                          }
                        })()}
                      </>
                    )}
                    <br />
                  </tbody>
                </table>
                <br />
                <br />
              </div>
            </>
          )}
        </>
      )}

      <br />
      <br />
    </div>
  );
}
