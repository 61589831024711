import { initializeApp } from 'firebase/app';
import { getFirestore, Timestamp } from "firebase/firestore";
import { 
  getAuth, 
  PhoneAuthProvider,
  multiFactor,
  PhoneMultiFactorGenerator,
  EmailAuthProvider,
  reauthenticateWithCredential,
  RecaptchaVerifier,
  getMultiFactorResolver
} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from 'firebase/functions';

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const apersuDatabase = getFirestore(app);
const apersuAuthentication = getAuth(app);
const apersuStorage = getStorage(app);
const functions = getFunctions(app);

// Export services and functions
export { 
  apersuDatabase, 
  apersuAuthentication, 
  apersuStorage, 
  functions, 
  app,
  PhoneAuthProvider,
  multiFactor,
  PhoneMultiFactorGenerator,
  EmailAuthProvider,
  reauthenticateWithCredential,
  RecaptchaVerifier,
  getMultiFactorResolver,
  Timestamp 
};