import React, { useState, useEffect } from "react";
import { apersuDatabase } from "../../../firebase/config";
import {
  collection,
  getDocs,
  setDoc,
  doc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { formatDistanceToNow, format } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthContext } from "../../../hooks/useAuthContext";
import PoundIcon from "../../../assets/icons/FormFields/PoundIcon";
import { CheckCircle, Clock, XCircle } from "@phosphor-icons/react";

function LabourRates() {
  const { user } = useAuthContext();
  const [documents, setDocuments] = useState([]);
  const [updatedRates, setUpdatedRates] = useState({});
  const [errors, setErrors] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRate, setSelectedRate] = useState(null);

  useEffect(() => {
    if (user) {
      fetchDocuments();
    }
  }, [user]);

  const getDate = (dateOrTimestamp) => {
    if (dateOrTimestamp instanceof Date) {
      return dateOrTimestamp;
    }
    if (dateOrTimestamp && typeof dateOrTimestamp.toDate === "function") {
      return dateOrTimestamp.toDate();
    }
    if (typeof dateOrTimestamp === "string") {
      return new Date(dateOrTimestamp);
    }
    return null;
  };

  const fetchDocuments = async () => {
    try {
      const defaultSnapshot = await getDocs(
        collection(apersuDatabase, "users/default/info/labourRates/defaultLabourRates")
      );
      let docs = defaultSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        isDefault: true,
      }));

      if (user) {
        const userRatesRef = collection(
          apersuDatabase,
          `users/${user.uid}/labourRates`
        );
        const userRatesSnapshot = await getDocs(userRatesRef);

        if (!userRatesSnapshot.empty) {
          const userRates = userRatesSnapshot.docs.map((doc) => {
            const data = doc.data();
            // Convert ISO string to Date object for each rate in history
            if (data.rateHistory) {
              data.rateHistory = data.rateHistory.map((rate) => ({
                ...rate,
                timestamp: getDate(rate.timestamp),
              }));
            }
            // Use getDate function for _lastUpdated as well
            if (data._lastUpdated) {
              data._lastUpdated = getDate(data._lastUpdated);
            }
            return { id: doc.id, ...data };
          });
          docs = docs.map((doc) => {
            const userRate = userRates.find((rate) => rate.id === doc.id);
            return userRate ? { ...doc, ...userRate, isDefault: false } : doc;
          });
        }
      }

      docs.sort((a, b) => a.listOrder - b.listOrder);
      setDocuments(docs);
    } catch (error) {
      toast.error("Error fetching rates: " + error.message);
    }
  };

  const handleRateChange = (id, newRate) => {
    setUpdatedRates((prev) => ({ ...prev, [id]: newRate }));
    setErrors((prev) => ({ ...prev, [id]: null }));
  };

  const validateInputs = () => {
    const newErrors = {};
    documents.forEach((doc) => {
      if (!doc.currentRate && !updatedRates[doc.id]) {
        newErrors[doc.id] = "This field is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const updateRates = async () => {
    if (!validateInputs()) {
      toast.error(
        "All labour rates that have not been set must be filled out."
      );
      return;
    }

    try {
      const userRatesRef = collection(
        apersuDatabase,
        `users/${user.uid}/labourRates`
      );

      const clientNow = new Date(); // Get current client time for immediate display and rateHistory

      for (const [id, newRate] of Object.entries(updatedRates)) {
        const rateDocRef = doc(userRatesRef, id);
        const currentDoc = documents.find((doc) => doc.id === id);

        const docSnap = await getDoc(rateDocRef);
        const currentData = docSnap.exists() ? docSnap.data() : {};

        const newRateEntry = {
          value: Number(newRate),
          timestamp: clientNow.toISOString(), // Use ISO string for rateHistory entries
        };

        let rateHistory = currentData.rateHistory || [];
        rateHistory.unshift(newRateEntry);
        rateHistory = rateHistory.slice(0, 5);

        await setDoc(
          rateDocRef,
          {
            rateHistory: rateHistory,
            currentRate: Number(newRate),
            _lastUpdated: serverTimestamp(), // Use serverTimestamp for the top-level field
            tradeName: currentDoc.tradeName,
          },
          { merge: true }
        );

        // Update the local state immediately for display purposes
        setDocuments((prevDocs) =>
          prevDocs.map((doc) =>
            doc.id === id
              ? {
                  ...doc,
                  _lastUpdated: clientNow.toISOString(),
                  currentRate: Number(newRate),
                }
              : doc
          )
        );
      }

      setUpdatedRates({});
      toast.success("Rates updated successfully!");
    } catch (error) {
      // toast.error("Error updating rates: " + error.message);
    }
  };

  const openModal = (doc) => {
    setSelectedRate(doc);
    document.getElementById('rate_history_modal').showModal();
  };

  const closeModal = () => {
    document.getElementById('rate_history_modal').close();
    setSelectedRate(null);
  };

  const restoreRate = async (rate) => {
    try {
      const userRatesRef = collection(
        apersuDatabase,
        `users/${user.uid}/labourRates`
      );
      const rateDocRef = doc(userRatesRef, selectedRate.id);

      const clientNow = new Date(); // Get current client time for immediate display

      await setDoc(
        rateDocRef,
        {
          currentRate: rate.value,
          _lastUpdated: serverTimestamp(), // Use serverTimestamp for actual update
          rateHistory: [
            ...selectedRate.rateHistory,
            {
              value: rate.value,
              timestamp: clientNow.toISOString(),
            },
          ],
        },
        { merge: true }
      );

      // Update the local state immediately for display purposes
      setDocuments((prevDocs) =>
        prevDocs.map((doc) =>
          doc.id === selectedRate.id
            ? {
                ...doc,
                _lastUpdated: clientNow,
                currentRate: rate.value,
                rateHistory: [
                  ...doc.rateHistory,
                  {
                    value: rate.value,
                    timestamp: clientNow.toISOString(),
                  },
                ],
              }
            : doc
        )
      );

      closeModal();
      toast.success("Rate restored successfully!");
    } catch (error) {
      toast.error("Error restoring rate: " + error.message);
    }
  };

  return (
    <div className="card mx-auto lg:w-11/12 md:w-full shadow-xl pb-15 w-full mb-10">
      <table className="table">
        <thead className="tableHeader">
          <tr className="max-sm:w-fit">
            <th className="max-sm:w-fit ps-6 md:ps-10 lg:ps-10 xl:ps-32 max-sm:ps-auto">
              Tradesperson
            </th>
            <th className="max-sm:w-fit">Day Rate</th>
            <th className="max-sm:w-fit">New Day Rate</th>
            <th className="max-sm:hidden">Last Updated</th>
            <th className="max-sm:hidden">History</th>
          </tr>
        </thead>
        <tbody className="tableBody">
          {documents.map((doc) => (
            <tr key={doc.id}>
              <td className="md:ps-20">{doc.tradeName}</td>
              <td>£{doc.currentRate}</td>
              <td>
                <label className="input input-sm max-sm:input-xs input-bordered flex items-center gap-2 input-white w-32 max-sm:w-20">
                  <PoundIcon />
                  <input
                    className="w-14"
                    type="number"
                    maxLength="3"
                    value={updatedRates[doc.id] || ""}
                    onChange={(e) => handleRateChange(doc.id, e.target.value)}
                    placeholder="New rate"
                    required={!doc.currentRate}
                  />
                </label>
              </td>
              <td className="max-sm:hidden">
                {doc._lastUpdated
                  ? formatDistanceToNow(getDate(doc._lastUpdated), {
                      addSuffix: true,
                    })
                  : "Never"}
              </td>
              <td className="max-sm:hidden">
                <button
                  className="btn btn-sm"
                  onClick={() => openModal(doc)}
                  disabled={!doc.rateHistory || doc.rateHistory.length === 0}>
                  <Clock size={20} />
                  History
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        className="btn sm:btn-wide mx-auto btn-secondary pb-15 my-6"
        onClick={updateRates}>
        <CheckCircle size={25} />
        Update Rates
      </button>
      <dialog id="rate_history_modal" className="modal">
        <div className="modal-box shadow-xl border-primary bordered border-4">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={closeModal}>
              <XCircle size={30}/>
            </button>
          </form>
          {selectedRate && (
            <>
              <h4 className=" pageSubtitle">
                Rate History for<br/>{selectedRate.tradeName}
              </h4>
              {selectedRate.rateHistory && selectedRate.rateHistory.length > 0 ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Rate</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedRate.rateHistory.map((rate, index) => (
                      <tr key={index}>
                        <td>{format(rate.timestamp, "dd/MM/yy")}</td>
                        <td>£{rate.value}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => restoreRate(rate)}
                          >
                            Restore
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No rate history available.</p>
              )}
            </>
          )}
            <div className="modal-action">
            <button className="btn btn-sm btn-ghost mt-4" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
      <ToastContainer position="top-right" autoClose={5000} />
    </div>
  );
}

export default LabourRates;