import React, { useEffect, useRef, useState } from 'react';
import { useLoadScript } from '@react-google-maps/api';

const libraries = ['places'];

const GoogleMap = ({ address, height = '600px' }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const mapRef = useRef(null);
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (isLoaded) {
      const newMap = new window.google.maps.Map(mapRef.current, {
        center: { lat: 54.5, lng: -4 }, // Center of UK
        zoom: 5,
        disableDefaultUI: true, // Disable all default controls
        gestureHandling: 'none', // Prevent scrolling, dragging, etc.
        draggable: false, // Disable dragging
        styles: [ // Hide POIs and other features
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'transit',
            stylers: [{ visibility: 'off' }],
          },
        ],
      });
      setMap(newMap);
    }
  }, [isLoaded]);

  useEffect(() => {
    if (map && address) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === 'OK' && results[0]) {
          const location = results[0].geometry.location;

          // Smooth pan to the new location
          map.panTo(location);

          // Smooth zoom using timeouts
          let currentZoom = map.getZoom();
          const targetZoom = 16;
          const step = targetZoom > currentZoom ? 1 : -1;

          const zoomInterval = setInterval(() => {
            if (currentZoom !== targetZoom) {
              currentZoom += step;
              map.setZoom(currentZoom);
            } else {
              clearInterval(zoomInterval);
            }
          }, 190); // Adjust time to make zooming faster or slower

          // Add the marker at the new location
          new window.google.maps.Marker({
            map: map,
            position: location,
          });
        }
      });
    }
  }, [map, address]);

  return <div ref={mapRef} style={{ height: height, width: '100%', borderRadius: 15}} />;
};

export default GoogleMap;