import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { Image } from '@phosphor-icons/react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, setDoc } from 'firebase/firestore';
import { apersuDatabase } from '../../firebase/config'; // Ensure this path is correct for your Firebase config
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CompanyLogoCard() {
  const { user } = useAuthContext();
  const [logo, setLogo] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (user) {
      fetchLogo();
    }
  }, [user]);

  const fetchLogo = async () => {
    const storage = getStorage();
    const logoRef = ref(storage, `CustomerData/${user.uid}/CompanyLogo`);
    try {
      const url = await getDownloadURL(logoRef);
      setLogo(url);
    } catch (error) {
      console.error("Error fetching logo:", error);
      // Set to default logo if user's logo doesn't exist
      const defaultLogoRef = ref(storage, '_default/defaultLogo.png');
      const defaultUrl = await getDownloadURL(defaultLogoRef);
      setLogo(defaultUrl);
    }
  };

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!selectedFile) return;

    const storage = getStorage();
    const logoRef = ref(storage, `CustomerData/${user.uid}/CompanyLogo`);
    const uploadTask = uploadBytesResumable(logoRef, selectedFile);

    setIsUploading(true);

    uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error("Upload error:", error);
        setIsUploading(false);
        toast.error('Failed to upload logo. Please try again.', {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setLogo(downloadURL);
          setIsUploading(false);
          setSelectedFile(null);
          
          // Update user document with new logo URL
          const userDocRef = doc(apersuDatabase, `/data/profiles/customers/${user.uid}/info/profile`);
          await setDoc(userDocRef, { logoUrl: downloadURL }, { merge: true });

          toast.success('Logo uploaded successfully!', {
            position: "top-right",
            autoClose: 2000,
            containerID: "companyLogoCard",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } catch (error) {
          console.error("Error updating user document:", error);
          toast.error('Failed to update user information. Please try again.', {
            position: "top-right",
            autoClose: 2000,
            containerID: "companyLogoCard",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }
    );
  };

  return (
    <div className='text-center'>
      <ToastContainer 
    limit={1} 
    autoClose={2500} 
    enableMultiContainer 
    containerId="companyLogoCard"
  />
      <div className="card card-compact md:card-side bg-base-100 xl:max-w-screen-2xl md:max-w-7xl md:min-w-xl mx-auto shadow-xl mb-20">
        <figure className='md:w-2/5 p-5'>

        <div className="card border-2 border-primary">
          <img
            className='cardImage card companyLogoImage'
            src={logo}
            alt={user ? `${user.displayName} Logo` : 'Company Logo'}
          />
          </div>
        </figure>
        <div className="card-body">
          <h2 className="card-title text-left">Change the logo for {user && user.displayName}</h2>
          <div className="max-sm:text-sm label text-left">
            Upload your company logo to display it in the header of your estimates.
          </div>
          <div className="card-actions md:justify-start justify-center">
            <div className='text-left'>
              <form onSubmit={handleUpload}>
                <label className="form-control w-full justify-items-start max-w-xl">
                  <input 
                    className="file-input file-input-bordered file-input-secondary w-full"
                    type="file"
                    accept="image/*"
                    onChange={handleFileSelect}
                    disabled={isUploading}
                  />
                  <div className="label">
                    {isUploading && (
                      <progress className="progress progress-secondary w-full" value={uploadProgress} max="100"></progress>
                    )}
                  </div>
                  <button 
                    className="btn max-md:btn-sm btn-secondary"
                    type="submit"
                    disabled={isUploading || !selectedFile}
                  >
                    <Image size={25}/> {isUploading ? 'Uploading...' : 'Update Company Logo'}
                  </button>
                </label>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}