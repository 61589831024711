// ForgotPassword.js
import React, { useState, useEffect } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { At, PaperPlaneTilt, X, XCircle } from '@phosphor-icons/react';

const ForgotPassword = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        let timer;
        if (isSubmitted) {
            timer = setTimeout(() => {
                onClose();
            }, 5000);
        }
        return () => clearTimeout(timer);
    }, [isSubmitted, onClose]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const auth = getAuth();
        try {
            await sendPasswordResetEmail(auth, email);
            setMessage("We've sent you an email with instructions on how to reset your password. Please check your inbox.");
            setIsSubmitted(true);
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }
    };

    return (
        <div className="modal-box shadow-xl border-primary bordered border-4">
            <form method="dialog">
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={onClose}><XCircle size={30}/></button>
            </form>
            <h3 className="font-bold pageSubtitle text-lg">Reset Password</h3>
            <p className="py-4">If you've forgotten your password, enter your email address here and we'll send you a password reset link.</p>
            <form onSubmit={handleSubmit}>
                <div className="form-control">
                    <label className="input input-bordered flex items-center gap-2">
                        <At size={25}/>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="grow"
                        />
                    </label>
                </div>
                <br/>
                <button type="submit" className="btn lg:btn-md btn-primary">
                    <PaperPlaneTilt size={25}/>&nbsp;Send Reset Email
                </button>
            </form>
            {message && <p className="mt-4">{message}</p>}
            <br/><br/>
        </div>
    );
};

export default ForgotPassword;