import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import LoadingButton from "../../components/Buttons/LoadingButton";
import LoginButton from "../../components/Buttons/LoginButton";
import {
  Eye,
  EyeSlash,
  At,
  Password,
  PencilLine,
  Phone,
  XCircle,
  Info,
} from "@phosphor-icons/react";
import {
  signInWithEmailAndPassword,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
  RecaptchaVerifier,
  multiFactor,
} from "firebase/auth";
import { apersuAuthentication } from "../../firebase/config";
import { useAuthContext } from "../../hooks/useAuthContext";

const errorMessages = {
  "auth/user-not-found": "No user found with this email address.",
  "auth/wrong-password": "Incorrect password. Please try again.",
  "auth/invalid-email": "Invalid email format. Please check your email.",
  "auth/user-disabled":
    "This account has been disabled. Please contact support.",
  "auth/too-many-requests":
    "Too many failed login attempts. Please try again later.",
  "auth/network-request-failed":
    "Network error. Please check your internet connection.",
  "auth/invalid-verification-code":
    "Invalid verification code. Please try again.",
  default: "An error occurred. Please try again.",
};

export default function LoginForm() {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState("");
  const [mfaVerificationId, setMfaVerificationId] = useState("");
  const [isMfaRequired, setIsMfaRequired] = useState(false);
  const [resolver, setResolver] = useState(null);
  const [mfaVerificationCode, setMfaVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [isPending, setIsPending] = useState(false);

  const navigate = useNavigate();
  const { dispatch } = useAuthContext();

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      apersuAuthentication,
      "recaptcha-container",
      {
        size: "invisible",
      }
    );

    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }
    };
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setFormError("");
      setIsPending(true);

      try {
        const userCredential = await signInWithEmailAndPassword(
          apersuAuthentication,
          email,
          password
        );
        // Dispatch the login action to update the auth context
        dispatch({ type: "LOGIN", payload: userCredential.user });
        console.log("Login successful, navigating to /MyProfile");
        navigate("/MyProfile");
      } catch (error) {
        console.error("Login error:", error);
        if (error.code === "auth/multi-factor-auth-required") {
          await handleMFARequired(error);
        } else {
          setFormError(errorMessages[error.code] || errorMessages.default);
          setIsPending(false);
        }
      }
    },
    [email, password, navigate, dispatch]
  );

  const handleMFARequired = async (error) => {
    try {
      setIsMfaRequired(true);
      const multiFactorResolver = getMultiFactorResolver(
        apersuAuthentication,
        error
      );
      setResolver(multiFactorResolver);

      const phoneInfoOptions = {
        multiFactorHint: multiFactorResolver.hints[0],
        session: multiFactorResolver.session,
      };
      const phoneProvider = new PhoneAuthProvider(apersuAuthentication);
      const verificationId = await phoneProvider.verifyPhoneNumber(
        phoneInfoOptions,
        window.recaptchaVerifier
      );
      setMfaVerificationId(verificationId);
    } catch (verificationError) {
      console.error("Error sending verification code:", verificationError);
      setFormError("Failed to send verification code. Please try again.");
      setIsMfaRequired(false);
    } finally {
      setIsPending(false);
    }
  };

  const handleMFASubmit = async (e) => {
    e.preventDefault();
    setFormError("");
    setIsPending(true);

    try {
      const cred = PhoneAuthProvider.credential(
        mfaVerificationId,
        mfaVerificationCode.join("")
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      const userCredential = await resolver.resolveSignIn(multiFactorAssertion);
      // Dispatch the login action to update the auth context
      dispatch({ type: "LOGIN", payload: userCredential.user });
      console.log("MFA successful, navigating to /MyProfile");
      navigate("/MyProfile");
    } catch (error) {
      console.error("MFA verification failed", error);
      setFormError(errorMessages[error.code] || errorMessages.default);
    } finally {
      setIsPending(false);
    }
  };

  const handleMFACodeChange = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newCode = [...mfaVerificationCode];
      newCode[index] = value;
      setMfaVerificationCode(newCode);
      
      if (value !== '' && index < 5) {
        document.getElementById(`mfa-code-${index + 1}`).focus();
      }
    }
  };

  const handleMFAKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      index > 0 &&
      mfaVerificationCode[index] === ""
    ) {
      document.getElementById(`mfa-code-${index - 1}`).focus();
    }
  };

  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
    setFormError("");
  }, []);

  const handlePasswordChange = useCallback((e) => {
    setPassword(e.target.value);
    setFormError("");
  }, []);

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);


  const handleOpenForgotPassword = () => {
    document.getElementById('forgot_password_modal').showModal();
  };

  const handleCloseForgotPassword = () => {
    document.getElementById('forgot_password_modal').close();
  };

  return (
    <div className="text-center lg:text-left hero sm:min-h-full bg-base-100">
      <div className="hero-content flex-col lg:flex-row-reverse">
        <div className="pl-0 lg:pl-10 lg:w-1/2 lg:self-start lg:sticky lg:top-8 lg:mt-20">
          <div className="divider lg:divider-start divider-primary pageTitleSm">
            Log In
          </div>
          <p className="sm:py-5 w-full max-w-md">
            Please enter your email address and password here. If you don't have
            an account, please sign up using the button below.
          </p>
          <div className="sm:hidden">
            <br />
          </div>
          <Link to="/Signup">
            <button className="btn btn-wide btn-secondary">
              <PencilLine size={25} />
              Create an Account
            </button>
          </Link>
        </div>
        <div
          className="card shrink-0 w-full max-w-md shadow-2xl bg-base-200"
          style={{ width: "448px" }}
        >
          {!isMfaRequired ? (
            <form className="card-body" onSubmit={handleSubmit}>
              <div className="form-control pb-3">
                <label className="input input-bordered flex items-center gap-2">
                  <At size={25} />
                  <input
                    type="email"
                    className="grow"
                    placeholder="Email"
                    id="1email"
                    onChange={handleEmailChange}
                    value={email}
                  />
                </label>
              </div>
              <div className="form-control">
                <label className="input input-bordered flex items-center gap-2 pe-0">
                  <Password size={25} />
                  <input
                    type={showPassword ? "text" : "password"}
                    className="grow"
                    placeholder="Password"
                    id="1password"
                    onChange={handlePasswordChange}
                    value={password}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="btn btn-link text-base-content"
                  >
                    {showPassword ? <EyeSlash size={25} /> : <Eye size={25} />}
                  </button>
                </label>
                <button
        type="button"
        className="btn btn-secondary btn-sm w-auto mt-2"
        onClick={handleOpenForgotPassword}
      >
        Forgot Password?
      </button>
              </div>
              <div className="form-control mt-14 ">
                {!isPending ? <LoginButton type="submit" /> : <LoadingButton />}
              </div>
            </form>
          ) : (
            <form className="card-body" onSubmit={handleMFASubmit}>
              <div className="form-control">
                <h3 className="text-lg font-bold mb-4">
                  Enter Verification Code
                </h3>
                <p className="text-sm text-gray-600 mb-4">
                  <Info size={20} className="inline mr-1" />
                  Multi-Factor Authentication (MFA) provides an extra layer of
                  security. Please enter the 6-digit code sent to your phone.
                </p>
                <div className="join justify-center mb-4">
                  {mfaVerificationCode.map((digit, index) => (
                    <input
                      key={index}
                      id={`mfa-code-${index}`}
                      type="tel"
                      maxLength="1"
                      value={digit}
                      onChange={(e) =>
                        handleMFACodeChange(index, e.target.value)
                      }
                      onKeyDown={(e) => handleMFAKeyDown(index, e)}
                      className="join-item w-12 h-12 text-center text-xl input input-bordered focus:outline-none focus:ring-2 focus:ring-primary"
                    />
                  ))}
                </div>
              </div>
              <div className="form-control mt-6">
                {!isPending ? (
                  <button type="submit" className="btn btn-primary">
                    Verify and Login
                  </button>
                ) : (
                  <LoadingButton />
                )}
              </div>
            </form>
          )}
          {formError && (
            <div className="alert alert-error mt-4">
              <XCircle size={20} />
              <span>{formError}</span>
            </div>
          )}
        </div>
      </div>
      <dialog id="forgot_password_modal" className="modal">
        <ForgotPassword onClose={handleCloseForgotPassword} />
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
      <div id="recaptcha-container"></div>
    </div>
  );
}
