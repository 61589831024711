// MFAManager.js
import React, { useState, useRef, useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import {
  PhoneAuthProvider,
  multiFactor,
  PhoneMultiFactorGenerator,
  EmailAuthProvider,
  reauthenticateWithCredential,
  RecaptchaVerifier,
  getMultiFactorResolver,
  getAuth,
} from "firebase/auth";
import { apersuAuthentication } from "../firebase/config";
import {
  Shield,
  SealCheck,
  ShieldCheck,
  ShieldWarning,
  XCircle,
  CaretCircleRight,
  CaretLeft,
  CheckCircle,
  ArrowClockwise,
  CaretCircleLeft,
  ChatCircleText,
  WarningCircle,
} from "@phosphor-icons/react";
import LoadingButton from "../components/Buttons/LoadingButton";

const MFAManager = ({ onClose, isMFAEnabled, setIsMFAEnabled }) => {
  // State variables
  const [currentStep, setCurrentStep] = useState(
    isMFAEnabled ? "mfaEnabled" : "intro"
  );
  const [phoneNumber, setPhoneNumber] = useState("07");
  const [verificationCode, setVerificationCode] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(30);
  const [closeCountdown, setCloseCountdown] = useState(0);
  const [mfaResolver, setMfaResolver] = useState(null);
  const [reauthPassword, setReauthPassword] = useState("");
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);

  const { user } = useAuthContext();
  const auth = getAuth();
  const verificationInputRefs = useRef([]);
  const recaptchaVerifier = useRef(null);

  // Initialize RecaptchaVerifier
  useEffect(() => {
    if (!recaptchaVerifier.current) {
      recaptchaVerifier.current = new RecaptchaVerifier(
        apersuAuthentication,
        "recaptcha-container",
        {
          size: "invisible",
        }
      );
    }
  }, []);

  // Handle resend countdown
  useEffect(() => {
    let timer;
    if (resendDisabled) {
      timer = setInterval(() => {
        setResendCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setResendDisabled(false);
            return 30;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendDisabled]);

  // Handle close countdown
  useEffect(() => {
    let timer;
    if (closeCountdown > 0) {
      timer = setInterval(() => {
        setCloseCountdown((prev) => {
          if (prev <= 0) {
            clearInterval(timer);
            return 0;
          }
          return prev - 0.01;
        });
      }, 10);
    }
    return () => clearInterval(timer);
  }, [closeCountdown]);

  // Format phone number to international format
  const formatPhoneNumber = (number) => {
    const cleaned = number.replace(/\D/g, "");
    if (cleaned.startsWith("07") && cleaned.length === 11) {
      return "+44" + cleaned.slice(1);
    }
    return null;
  };

  // Handle phone number submission
  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    const formattedNumber = formatPhoneNumber(phoneNumber);
    if (!formattedNumber) {
      setError("Please enter a valid UK mobile number starting with 07.");
      setIsLoading(false);
      return;
    }
  
    try {
      const session = await multiFactor(user).getSession();
      const phoneAuthProvider = new PhoneAuthProvider(apersuAuthentication);
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        { phoneNumber: formattedNumber, session },
        recaptchaVerifier.current
      );
      setVerificationId(verificationId);
      setCurrentStep("verifyCode");
      setResendDisabled(true);
    } catch (error) {
      handleFirebaseError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle MFA disabling process
  const handleDisableMFA = async () => {
    setError("");
    setIsLoading(true);

    try {
      const currentUser = auth.currentUser;
      if (!currentUser) throw new Error("User not found");

      await currentUser.reload();

      const enrolledFactors = currentUser.multiFactor.enrolledFactors;
      if (!enrolledFactors || enrolledFactors.length === 0) {
        setSuccess("MFA is already disabled.");
        setIsMFAEnabled(false);
        setTimeout(onClose, 2000);
        return;
      }

      setCurrentStep("enterPassword");
    } catch (error) {
      console.error("Error in handleDisableMFA:", error);
      handleFirebaseError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle password submission for MFA disabling
  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("No authenticated user found");
      }

      const credential = EmailAuthProvider.credential(user.email, password);
      
      await reauthenticateWithCredential(user, credential);
      
      const multiFactorUser = multiFactor(user);
      const enrolledFactors = multiFactorUser.enrolledFactors;

      if (!enrolledFactors || enrolledFactors.length === 0) {
        setError("MFA is not currently enabled for this account.");
        setCurrentStep("mfaEnabled");
        return;
      }

      await multiFactorUser.unenroll(enrolledFactors[0]);
    } catch (error) {
      console.error("Error in handlePasswordSubmit:", error);
      if (error.code === 'auth/multi-factor-auth-required') {
        const resolver = getMultiFactorResolver(auth, error);
        setMfaResolver(resolver);
        
        if (resolver.hints && resolver.hints.length > 0) {
          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session
          };
          const phoneAuthProvider = new PhoneAuthProvider(auth);
          const verificationId = await phoneAuthProvider.verifyPhoneNumber(
            phoneInfoOptions,
            recaptchaVerifier.current
          );
          setVerificationId(verificationId);
          setCurrentStep("enterMfaCode");
        } else {
          setError("No MFA methods available for verification.");
        }
      } else {
        handleFirebaseError(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Handle reauthentication
  const handleReauthenticate = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const currentUser = auth.currentUser;
      const credential = EmailAuthProvider.credential(currentUser.email, reauthPassword);
      
      console.log("Attempting to reauthenticate user");
      await reauthenticateWithCredential(currentUser, credential);
      console.log("User reauthenticated successfully");
      
      await handleDisableMFA();
    } catch (error) {
      console.error("Error in handleReauthenticate:", error);
      handleFirebaseError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle MFA challenge
  const handleMfaChallenge = async (resolver) => {
    try {
      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session,
      };
      const phoneAuthProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier.current
      );
      setVerificationId(verificationId);
      setCurrentStep("enterMfaCode");
    } catch (error) {
      console.error("Error in handleMfaChallenge:", error);
      handleFirebaseError(error);
    }
  };

  // Handle Firebase errors
  const handleFirebaseError = (error) => {
    console.error("Firebase error:", error);
    switch (error.code) {
      case "auth/requires-recent-login":
        setError(
          "For security reasons, please re-enter your password to continue."
        );
        setCurrentStep("reauthenticate");
        break;
      case "auth/invalid-verification-code":
        setError(
          "The verification code you entered is invalid. Please check and try again."
        );
        break;
      case "auth/invalid-phone-number":
        setError(
          "The phone number you entered is invalid. Please enter a valid UK mobile number."
        );
        break;
      case "auth/wrong-password":
        setError("The password you entered is incorrect. Please try again.");
        break;
      case "auth/too-many-requests":
        setError(
          "Too many unsuccessful attempts. For security reasons, please try again later."
        );
        break;
      default:
        setError(
          "An unexpected error occurred. Please try again later or contact support if the problem persists."
        );
    }
  };

  // Handle verification code input changes
  const handleVerificationCodeChange = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newVerificationCode = [...verificationCode];
      newVerificationCode[index] = value;
      setVerificationCode(newVerificationCode);

      if (value && index < 5) {
        verificationInputRefs.current[index + 1].focus();
      } else if (!value && index > 0) {
        verificationInputRefs.current[index - 1].focus();
      }
    }
  };

  // Handle resend code
  const handleResendCode = async () => {
    setResendDisabled(true);
    setError("");
    try {
      const session = await multiFactor(user).getSession();
      const phoneAuthProvider = new PhoneAuthProvider(apersuAuthentication);
      const newVerificationId = await phoneAuthProvider.verifyPhoneNumber(
        { phoneNumber: formatPhoneNumber(phoneNumber), session },
        recaptchaVerifier.current
      );
      setVerificationId(newVerificationId);
      setSuccess("Verification code resent successfully.");
    } catch (error) {
      handleFirebaseError(error);
    }
  };

  // Handle verification code submission
  const handleVerifySubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    const code = verificationCode.join("");
    if (code.length !== 6) {
      setError("Please enter a valid 6-digit code.");
      setIsLoading(false);
      return;
    }

    try {
      const cred = PhoneAuthProvider.credential(verificationId, code);
      await multiFactor(user).enroll(PhoneMultiFactorGenerator.assertion(cred));
      setIsMFAEnabled(true);
      setShowSuccessAnimation(true);
      setSuccess("Multi-factor authentication has been successfully enabled.");
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      handleFirebaseError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle MFA code submission for disabling
  const handleMfaCodeSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("No authenticated user found");
      }

      const code = verificationCode.join("");
      const cred = PhoneAuthProvider.credential(verificationId, code);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      await mfaResolver.resolveSignIn(multiFactorAssertion);

      const multiFactorUser = multiFactor(user);
      const enrolledFactors = multiFactorUser.enrolledFactors;

      if (!enrolledFactors || enrolledFactors.length === 0) {
        setError("MFA is not currently enabled for this account.");
        setCurrentStep("mfaEnabled");
        return;
      }

      await multiFactorUser.unenroll(enrolledFactors[0]);

      await user.getIdToken(true);
      await user.reload();

      setIsMFAEnabled(false);
      setShowSuccessAnimation(true);
      setSuccess("Multi-factor authentication has been successfully disabled.");
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error("Error in handleMfaCodeSubmit:", error);
      handleFirebaseError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Render different steps of the MFA process
  const RenderStep = () => {
    const [fadeState, setFadeState] = useState('in');
    
    useEffect(() => {
      setFadeState('in');
      return () => setFadeState('out');
    }, [currentStep]);
  
    const commonClasses = `
      flex flex-col items-center justify-between min-h-[300px] p-4
      transition-opacity duration-300 ease-in-out
      ${fadeState === 'in' ? 'opacity-100' : 'opacity-0'}
    `;
    const titleClasses = "text-2xl font-bold mb-4 w-full text-center";
    const contentClasses = "flex-grow flex flex-col items-center justify-center text-center space-y-4 w-full";
    const buttonContainerClasses = "flex flex-row space-x-4 mt-4 justify-center w-full";
  
    const renderButtons = (primaryButton, secondaryButton = null) => (
      <div className={buttonContainerClasses}>
        {secondaryButton}
        {primaryButton}
      </div>
    );
  
    const handleStepChange = (newStep) => {
      setFadeState('out');
      setTimeout(() => {
        setCurrentStep(newStep);
        setFadeState('in');
      }, 300);
    };

    switch (currentStep) {
      case "intro":
        return (
          <div className={commonClasses}>
            <h2 className={titleClasses}>Multi-Factor Authentication</h2>
            <div className={contentClasses}>
              <p className="text-center">
                MFA adds an extra layer of security to your account by requiring a
                second form of verification.
              </p>
              {renderButtons(
                <button onClick={onClose} className="btn btn-secondary w-44">
                  <XCircle size={25} />
                  Cancel
                </button>,
                <button
                  onClick={() => handleStepChange("enterPhone")}
                  className="btn btn-primary w-44"
                  disabled={isLoading}
                >
                  <CaretCircleRight size={25} />
                  Set up MFA
                </button>
              )}
            </div>
          </div>
        );
  
      case "mfaEnabled":
        return (
          <div className={commonClasses}>
            <h2 className={titleClasses}>MFA Options</h2>
            <div className={contentClasses}>
              <p className="text-center">
                Your account is currently protected with Multi-Factor
                Authentication.
              </p>
              {renderButtons(
                <button onClick={onClose} className="btn btn-primary w-44">
                  <XCircle size={25} />
                  Cancel
                </button>,
                <button
                  onClick={() => handleStepChange("confirmDisable")}
                  className="btn btn-secondary w-44"
                  disabled={isLoading}
                >
                  <ShieldWarning size={25} />
                  Disable MFA
                </button>
              )}
            </div>
          </div>
        );
  
      case "confirmDisable":
        return (
          <div className={commonClasses}>
            <h2 className={titleClasses}>Disable MFA</h2>
            <div className={contentClasses}>
              <p className="text-center">Are you sure you want to disable Multi-Factor Authentication?</p>
              {renderButtons(
                <button
                  onClick={() => handleStepChange("mfaEnabled")}
                  className="btn btn-primary w-44"
                  disabled={isLoading}
                >
                  <XCircle size={25} />
                  Cancel
                </button>,
                <button
                  onClick={() => handleStepChange("enterPassword")}
                  className="btn btn-secondary w-44"
                  disabled={isLoading}
                >
                  <CheckCircle size={25} />
                  Yes, Disable MFA
                </button>
              )}
            </div>
          </div>
        );
  
      case "enterPassword":
        return (
          <div className={commonClasses}>
            <h2 className={titleClasses}>Confirm Password</h2>
            <div className={contentClasses}>
              <p className="text-center">
                Please enter your password to start the MFA disabling process.
              </p>
              <form onSubmit={handlePasswordSubmit} className="w-full max-w-xs">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  className="input input-bordered w-full"
                  required
                />
                {renderButtons(
                  <button
                    type="button"
                    onClick={() => handleStepChange("mfaEnabled")}
                    className="btn btn-primary w-44"
                  >
                    <XCircle size={25} />
                    Cancel
                  </button>,
                  <button
                    type="submit"
                    className="btn btn-secondary w-44"
                    disabled={isLoading}
                  >
                    <CheckCircle size={25} />
                    Confirm
                  </button>
                )}
              </form>
            </div>
          </div>
        );
  
      case "enterPhone":
            return (
              <div className={commonClasses}>
                <h2 className={titleClasses}>Enter Your Phone Number</h2>
                <div className={contentClasses}>
                  <p className="text-center">
                    Please enter your UK mobile number.
                  </p>
                  <form onSubmit={handlePhoneSubmit} className="w-full max-w-xs">
                    <input
                      type="tel"
                      value={phoneNumber}
                      onChange={(e) => {
                        const input = e.target.value;
                        if (input.startsWith("07") && input.length <= 11) {
                          setPhoneNumber(input);
                        }
                      }}
                      placeholder="07XXX XXX XXX"
                      className="input input-bordered w-full"
                      required
                    />
                    {renderButtons(
                      <button
                        type="submit"
                        className="btn btn-primary w-44"
                        disabled={isLoading}
                      >
                        <ChatCircleText size={25} />
                        Send Code
                      </button>,
                      <button
                        type="button"
                        onClick={() => handleStepChange("intro")}
                        className="btn btn-secondary w-44"
                      >
                        <CaretLeft size={25} />
                        Back
                      </button>
                    )}
                  </form>
                </div>
              </div>
            );
      
      case "verifyCode":
        return (
          <div className={commonClasses}>
            <h2 className={titleClasses}>Enter Verification Code</h2>
            <div className={contentClasses}>
              {!showSuccessAnimation ? (
                <>
                  <p className="text-center">
                    Please enter the 6-digit code sent to your phone.
                  </p>
                  <form onSubmit={handleVerifySubmit} className="w-full max-w-xs">
                    <div className="join justify-center">
                      {verificationCode.map((digit, index) => (
                        <input
                          key={index}
                          type="tel"
                          maxLength="1"
                          value={digit}
                          onChange={(e) =>
                            handleVerificationCodeChange(index, e.target.value)
                          }
                          ref={(el) => (verificationInputRefs.current[index] = el)}
                          className="join-item w-12 h-12 text-center text-xl input input-bordered focus:outline-none focus:ring-2 focus:ring-primary"
                          required
                        />
                      ))}
                    </div>
                    {renderButtons(
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isLoading}
                      >
                        <CheckCircle size={25} />
                        Verify Code
                      </button>,
                      <button
                        type="button"
                        onClick={handleResendCode}
                        className="btn btn-secondary"
                        disabled={resendDisabled || isLoading}
                      >
                        <ArrowClockwise size={25} />
                        {resendDisabled
                          ? `Resend (${resendCountdown}s)`
                          : "Resend Code"}
                      </button>
                    )}
                  </form>
                  <button
                    type="button"
                    onClick={() => handleStepChange("enterPhone")}
                    className="btn btn-outline btn-secondary btn-sm mt-4"
                  >
                    <CaretCircleLeft size={25} />
                    Change Phone Number
                  </button>
                </>
              ) : (
                <div className="flex flex-col items-center">
                  <CheckCircle size={100} color="#4CAF50" weight="fill" />
                  <p className="mt-4 text-lg">
                    Multi-factor authentication has been successfully enabled.
                  </p>
                </div>
              )}
            </div>
          </div>
        );
  
      case "reauthenticate":
        return (
          <div className={commonClasses}>
            <h2 className={titleClasses}>Re-authenticate</h2>
            <div className={contentClasses}>
              <p className="text-center">
                For security reasons, please enter your password to continue.
              </p>
              <form onSubmit={handleReauthenticate} className="w-full max-w-xs">
                <input
                  type="password"
                  value={reauthPassword}
                  onChange={(e) => setReauthPassword(e.target.value)}
                  placeholder="Enter your password"
                  className="input input-bordered w-full"
                  required
                  autoComplete="current-password"
                />
                {renderButtons(
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading}
                  >
                    Confirm
                  </button>,
                  <button
                    type="button"
                    onClick={() => handleStepChange("mfaEnabled")}
                    className="btn btn-secondary"
                  >
                    Cancel
                  </button>
                )}
              </form>
            </div>
          </div>
        );
  
      case "enterMfaCode":
        return (
          <div className={commonClasses}>
            <h2 className={titleClasses}>Enter MFA Code</h2>
            <div className={contentClasses}>
              {!showSuccessAnimation ? (
                <>
                  <p className="text-center mt-10">
                    Please enter the 6-digit code sent to your phone to disable MFA.
                  </p>
                  <form onSubmit={handleMfaCodeSubmit} className="w-full max-w-xs">
                    <div className="join justify-center mt-5">
                      {verificationCode.map((digit, index) => (
                        <input
                          key={index}
                          type="tel"
                          maxLength="1"
                          value={digit}
                          onChange={(e) =>
                            handleVerificationCodeChange(index, e.target.value)
                          }
                          ref={(el) => (verificationInputRefs.current[index] = el)}
                          className="join-item w-14 h-14 text-center text-xl input input-bordered focus:outline-none focus:ring-2 focus:ring-primary"
                          required
                        />
                      ))}
                    </div>
                    {renderButtons(
                      <button
                        type="button"
                        onClick={() => handleStepChange("mfaEnabled")}
                        className="btn btn-secondary mt-5 w-44"
                      >
                        <XCircle size={25} />
                        Cancel
                      </button>,
                      <button
                        type="submit"
                        className="btn btn-primary mt-5"
                        disabled={isLoading}
                      >
                        <CheckCircle size={25} />
                        Verify and Disable MFA
                      </button>
                    )}
                  </form>
                </>
              ) : (
                <div className="flex flex-col items-center">
                  <WarningCircle size={100} color="#ff4013" weight="fill" />
                  <p className="mt-4 text-lg">
                    Multi-factor authentication has been successfully disabled.
                  </p>
                </div>
              )}
            </div>
          </div>
        );
  
      default:
        return null;
    }
  };

  return (
    <div className="mfa-manager">
      <div className="transition-opacity duration-300 ease-in-out min-h-[300px] flex flex-col justify-between">
        <div className="flex-grow flex items-center justify-center">
          {RenderStep()}
        </div>
        <div className="h-16 flex items-center justify-center">
          {error && <p className="error-message text-red-500">{error}</p>}
          {/* {success && (
            <p className="success-message text-green-500">{success}</p>
          )} */}
        </div>
      </div>
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default MFAManager;